<template>
  <table>
    <thead>
      <th>No</th>
      <th>Medicine Image</th>
      <th>Medicine Name</th>
      <th>Medicine Form</th>
      <th>Medicine Cost</th>

      <th>CTA</th>
    </thead>
    <tbody>
      <tr v-for="(medicine, index) in medicines" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <img
            v-if="medicine.medicineImage != null"
            :src="`https://scoreapi.gotmine.rw${medicine.medicineImage}`"
            alt="medicine Image"
          />
          <img
            v-else
            src="../../assets/img/logo.png"
            alt="Medicine doesn't have image!"
          />
        </td>
        <td>{{ medicine.medicine_name }}</td>
        <td>{{ medicine.medicine_form }}</td>
        <td>{{ medicine.medicine_price }} frw</td>
        <td>
          <div class="btn-container">
            <button>Edit</button>
            <button>Delete</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
// import img from "../../assets/img/Ibuprofen.jpg";
import { useToast } from "vue-toastification";
const Toast = useToast();

export default {
  data() {
    return {
      medicines: null,
    };
  },
  mounted() {
    this.fecthVisibleMedicines();
  },

  methods: {
    async fecthVisibleMedicines() {
      await axios
        .get("https://scoreapi.gotmine.rw/api/medicine/visible")
        .then((response) => {
          console.log(response.data.data);
          this.medicines = response.data.data;
        })
        .catch((error) => {
          Toast.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  thead {
    background-color: var(--green);

    th {
      padding: 20px;
      color: white;
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;

        .btn-container {
          width: 100%;
          display: flex;
          gap: 10px;
          button {
            padding: 10px;
            background-color: var(--green);
            color: white;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: var(--green-dark);
            }
          }
        }

        img {
          width: 70px;
        }
      }

      &:nth-child(even) {
        background-color: var(--gray);
      }
    }
  }
}
</style>
