<template>
  <table>
    <thead>
      <th>No</th>
      <th>Full Name</th>
      <th>Phone</th>
      <th>Address</th>
      <th>Date</th>
      <th>Uploaded Image</th>
      <th>CTA</th>
    </thead>
    <tbody>
      <tr v-for="(order, index) in orders" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ order.fullname }}</td>
        <td>{{ order.phone }}</td>
        <td>{{ order.address }}</td>
        <td>{{ order.date.slice(0, 10) }}</td>
        <td>{{ order.uploads == 1 ? "Yes" : "No" }}</td>
        <td>
          <button
            v-if="order.uploads != 1"
            @click="this.$router.push({ name: 'processRequest' })"
          >
            Process Request
          </button>
          <button v-else @click="openModal(order.medicines, order.id)">
            View Request
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      orders: "",
    };
  },
  mounted() {
    axios
      .get("https://scoreapi.gotmine.rw/api/orders/fetch")
      .then((response) => {
        const allOrders = response.data.data;

        // Check if allOrders is defined and not empty
        if (allOrders && allOrders.length > 0) {
          const newOrders = allOrders.filter(
            (order) => order.order_status === "completed"
          );
          this.orders = newOrders;
        } else {
          // Handle the case where allOrders is empty or undefined
          console.log("No orders found");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  thead {
    background-color: var(--green);

    th {
      padding: 20px;
      color: white;
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;

        button {
          padding: 10px;
          background-color: var(--green);
          color: white;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            background-color: var(--green-dark);
          }
        }
      }

      &:nth-child(even) {
        background-color: var(--gray);
      }
    }
  }
}
</style>
