import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../node_modules/vanilla-tilt/src/vanilla-tilt.js"

import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";

import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/@fortawesome/fontawesome-free/js/all.js";


createApp(App).use(store).use(Toast).use(router).mount("#app");
