<template>
  <div class="container">
    <div class="row">
      <h2>Completed Requests</h2>
    </div>

    <div class="row">
      <div class="col chart">
        <canvas id="myCompletedChart"></canvas>
      </div>
      <div class="col config">
        <div class="row datePicker">
          <div class="col">
            <label for="start">Start date</label>
            <input type="date" id="Completestart" />
          </div>
          <div class="col">
            <label for="end">End date</label>
            <input type="date" id="Completeend" />
          </div>
        </div>
        <!-- <div class="row">
          <button @click="UpdateOrders" class="viewBtn">View Graph</button>
        </div> -->

        <div class="row">
          <div class="results-box">
            <div class="row">
              <div class="col finalData">
                <h1>{{ CompletedRequests }}</h1>

                <div class="f1">
                  <h2>Requests</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  data() {
    return {
      orders: [],
      ChartOrders: [],
      startDate: "",
      endDate: "",
      today: "",
    };
  },
  computed: {
    CompletedRequests() {
      let Total = 0;
      this.ChartOrders.forEach((order) => {
        Total += order.requests;
      });

      return Total;
    },
  },
  mounted() {
    const startInput = document.getElementById("Completestart");
    const endInput = document.getElementById("Completeend");

    startInput.addEventListener("change", () => {
      this.startDate = startInput.value;
      this.UpdateOrders();
    });
    endInput.addEventListener("change", () => {
      this.endDate = endInput.value;
      this.UpdateOrders();
    });

    axios
      .get("https://scoreapi.gotmine.rw/api/orders/completed")
      .then((response) => {
        const today = new Date().toISOString().slice(0, 10);
        this.today = today;
        const Mydata = response.data.data;

        const Orders = Mydata.map((order) => {
          order.date = order.date.slice(0, 10);
          const modifiedDate = new Date(order.date);
          modifiedDate.setDate(modifiedDate.getDate() + 1);

          const formattedDate = modifiedDate.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"

          return {
            date: formattedDate,
            requests: order.total_requests,
          };
        });

        console.log(Orders);

        this.orders = Orders;
        this.ChartOrders = Orders;

        // this.orders.forEach((order) => {
        //   console.log(order);
        // });

        // console.log(today);
        // endInput.value = today

        // Check if today's date is included in the orders data
        const todayOrder = this.orders.find((order) => order.date === today);
        if (!todayOrder) {
          // If today's date is not present, add it with total requests of zero
          this.orders.push({ date: today, requests: 0 });
        }

        // Sort the orders array by date
        this.orders.sort((a, b) => a.date.localeCompare(b.date));

        this.MakeChart();
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    filterDates(startDate, endDate) {
      if (endDate) {
        console.log(true);
        const filteredDates = this.orders.filter((order) => {
          return order.date >= startDate && order.date <= endDate;
        });

        return filteredDates;
      } else {
        console.log(false);
        const filteredDates = this.orders.filter((order) => {
          return order.date >= startDate && order.date <= this.today;
        });

        return filteredDates;
      }
    },

    UpdateOrders() {
      window.myCompletedChart.destroy();
      const filteredOrders = this.filterDates(this.startDate, this.endDate);

      //   console.log(this.orders);
      console.log(filteredOrders);
      this.ChartOrders = filteredOrders;

      // console.log(this.Orders);

      this.MakeChart();
    },

    MakeChart() {
      // Create the chart here after fetching the data
      const ctx = document.getElementById("myCompletedChart").getContext("2d");

      let delayed;

      // Gradient Fill

      let gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(58, 123, 213, 1)");
      gradient.addColorStop(1, "rgba(0, 210, 255, 0.3)");

      const labels = [];
      this.ChartOrders.forEach((order) => {
        labels.push(order.date);
      });

      const data = {
        labels,
        datasets: [
          {
            data: this.ChartOrders.map((order) => order.requests),
            label: "Completed Requests",
            fill: true,
            backgroundColor: gradient,
            borderColor: "#fff",
            pointBackgroundColor: "rgba(189, 195, 199, 0.4)",
            tension: 0,
          },
        ],
      };

      const config = {
        type: "line",
        data: data,
        options: {
          radius: 5,
          hitRadius: 30,
          hoverRadius: 12,
          responsive: true,

          animation: {
            onComplete: () => {
              delayed = true;
            },
            delay: (context) => {
              let delay = 0;
              if (
                context.type === "data" &&
                context.mode === "default" &&
                !delayed
              ) {
                delay = context.dataIndex * 300 + context.datasetIndex * 100;
              }

              return delay;
            },
          },

          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return value;
                },
                precision: 0,
              },
            },
          },
        },
      };

      window.myCompletedChart = new Chart(ctx, config);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;

  .col {
    width: 50%;
  }
}

.finalData {
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 30px;
  }
}

.container {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.281);
  padding: 20px;

  .row {
    .chart {
      width: 60%;
      height: 50vh;
      position: relative;
    }

    .config {
      width: 40%;

      .datePicker {
        gap: 20px;
        .col {
          display: flex;
          flex-direction: column;

          input[type="date"] {
            background-color: transparent;
            padding: 10px;
            border: 1px solid rgb(95, 170, 255);
            border-radius: 5px;
            color: rgb(95, 170, 255);
            outline: none;
          }

          ::-webkit-calendar-picker-indicator {
            background-color: white;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: rgb(194, 194, 194);
            }
          }
        }
      }

      .row {
        .viewBtn {
          width: 100%;
          padding: 10px;
          margin: 15px 0;
          border: none;
          background-color: rgb(71, 135, 207);
          color: white;
          transition: 200ms ease;

          &:hover {
            cursor: pointer;
            background-color: rgb(51, 101, 158);
          }
        }

        .results-box {
          width: 100%;
          margin-top: 20px;

          .col {
            width: 100%;
            display: flex;
            justify-content: center;
            h1 {
              font-size: 100px;
            }

            .finalData {
            }
          }
        }
      }
    }
  }
}
</style>
