import { createRouter, createWebHistory } from "vue-router";
import Auth from "../pages/AuthPage.vue";
import Dash from "../pages/dashboard.vue";
import dashboard from "../views/dashboard.vue";
import requests from "../views/requests.vue";
import customers from "../views/customers.vue";
import inventory from "../views/inventory.vue";
import reports from "../views/reports.vue";

// import Request Components
import newRequests from "../views/requests/newRequests.vue";
import pendingRequests from "../views/requests/pendingRequests.vue";
import completedRequests from "../views/requests/completedRequests.vue";
import processRequest from "../views/requests/processRequest.vue";

// import Inventory Components
import viewMedicine from "../views/inventory/viewMedicine.vue";
import addMedicine from "../views/inventory/addMedicine.vue";

import { useToast } from "vue-toastification";

const Toast = useToast();

const routes = [
  {
    path: "/",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/admin",
    name: "Dash",
    component: Dash,
    meta: {
      needsAuth: true,
    },
    redirect: "/admin/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: dashboard,
      },
      {
        path: "requests",
        name: "requests",
        component: requests,
        children: [
          {
            path: "new",
            name: "newRequests",
            component: newRequests,
          },
          {
            path: "pending",
            name: "pendingRequests",
            component: pendingRequests,
          },
          {
            path: "completed",
            name: "completedRequests",
            component: completedRequests,
          },
        ],
      },

      {
        path: "requests/process/:id",
        name: "processRequest",
        component: processRequest,
      },
      {
        path: "customers",
        name: "customers",
        component: customers,
      },
      {
        path: "inventory",
        name: "inventory",
        component: inventory,

        children: [
          {
            path: "view",
            name: "viewMedicine",
            component: viewMedicine,
          },
          {
            path: "add",
            name: "addMedicine",
            component: addMedicine,
          },
        ],
      },
      {
        path: "reports",
        name: "reports",
        component: reports,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) {
    if (localStorage.LoggedIn || sessionStorage.LoggedIn) {
      next();
    } else {
      next("");
      Toast.info("First Login to access this Page!");
    }
  } else {
    next();
  }
});
