<template>
  <div class="container">
    <!-- <div class="row firstRow">
      <div class="col">
        <div class="row">
          <div class="col">
            <h3>Fullname</h3>
            <p>Prince Christian</p>
          </div>
          <div class="col">
            <h3>Address</h3>
            <p>Kicukiro</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>Phone</h3>
            <p>0798833298</p>
          </div>
          <div class="col">
            <h3>Insurance</h3>
            <p>RSSB</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3>Date of birth</h3>
            <p>Prince Christian</p>
          </div>
          <div class="col">
            <h3>Insurance ID</h3>
            <p>Kicukiro</p>
          </div>
        </div>
      </div>
      <div class="col img-container">
        <img :src="img" alt="" />
        <button>View Prescription</button>
        <div class="overlay"></div>
      </div>
    </div> -->

    <div class="row firstRow">
      <div class="col">
        <div class="image-container">
          <img
            :src="`https://scoreapi.gotmine.rw` + PrescriptionURL"
            width="100%"
            alt=""
          />
        </div>
      </div>
      <div class="col">
        <div class="head">
          <p>Search and add the Medicine from prescription</p>
        </div>

        <div class="search-main-contianer">
          <div class="search-container">
            <input
              type="text"
              v-model="searchTerm"
              placeholder="Search for medicine"
            />
            <i class="fa fa-search searchIco"></i>
          </div>

          <div class="searched-content-container" v-if="searchTerm.length > 0">
            <div class="SearchOverlay" @click="hideSearchedItems"></div>

            <div class="searched-content" v-if="filteredMedicines.length > 0">
              <div
                class="Founditem"
                v-for="(medicine, index) in filteredMedicines"
                :key="index"
              >
                <div class="left">
                  <p>{{ medicine.medicine_name }}</p>
                </div>
                <div class="right">
                  <button @click="AddOrderItem(medicine.id)">Add +</button>
                </div>
              </div>
            </div>

            <div class="searched-content notFound" v-else>
              <div>
                <p>
                  Sorry there is no
                  <strong class="SearchTerm">{{ searchTerm }}</strong> in our
                  inventory
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="selectedItems-container">
          <div
            class="selectedItem"
            v-for="(item, index) in PlacedItems"
            :key="index"
          >
            <div class="left">
              <div class="">
                <h3>{{ item.medicine_name }}</h3>
              </div>
              <div class="">
                <div class="quantity-container">
                  <button class="btn" @click="DecreaseQuantity(item.id)">
                    -
                  </button>
                  <div class="quantity">{{ item.quantity }}</div>
                  <button class="btn" @click="IncreaseQuantity(item.id)">
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="first">
                <table>
                  <tr>
                    <td>Unit Price</td>
                    <td>: {{ item.medicine_price }} Frw</td>
                  </tr>
                  <tr>
                    <td>Quantity</td>
                    <td>: {{ item.quantity }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Price</strong></td>
                    <td>
                      : <strong>{{ item.totalPrice }} Frw</strong>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="second">
                <button @click="RemoveOrderItem(item.id)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="lastContainer" v-if="totalAmount > 0">
            <div class="total-container">
              <h2>Total Amount</h2>
              <h2>{{ totalAmount }} Frw</h2>
            </div>
          </div>

          <div class="button-container" v-if="totalAmount > 0">
            <button @click="SendBillInfo">Send Bill Info</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img from "../../assets/img/hospitalBg1.jpg";
import img2 from "../../assets/img/prescription.jpeg";
import axios from "axios";

import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  data() {
    return {
      img,
      img2,
      searchTerm: "",
      OrderItems: [],
      totalAmount: 0, // Initialize totalAmount property
      OrderId: this.$route.params.id,
      PrescriptionURL: "",
    };
  },
  mounted() {
    axios
      .get("https://scoreapi.gotmine.rw/api/medicine")
      .then((response) => {
        if (response.data.success) {
          this.$store.dispatch("addMedicine", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post("https://scoreapi.gotmine.rw/api/prescription", {
        order_id: this.OrderId,
      })
      .then((response) => {
        this.PrescriptionURL = response.data.data[0].image;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    filteredMedicines() {
      // Get the IDs of medicines already in OrderItems
      const orderedMedicineIds = this.OrderItems.map((item) => item.id);

      // Filter medicines to exclude those already in OrderItems
      return this.$store.state.medicines.filter(
        (medicine) =>
          medicine.medicine_name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) &&
          !orderedMedicineIds.includes(medicine.id)
      );
    },

    PlacedItems() {
      return this.OrderItems;
    },
  },
  methods: {
    hideSearchedItems() {
      this.searchTerm = "";
    },
    AddOrderItem(id) {
      const selectedMedicine = this.$store.state.medicines.find(
        (medicine) => medicine.id === id
      );
      if (selectedMedicine) {
        selectedMedicine.quantity = 1;
        selectedMedicine.totalPrice =
          selectedMedicine.quantity * selectedMedicine.medicine_price;
        this.OrderItems = this.OrderItems.concat(selectedMedicine);
        this.searchTerm = "";
        this.calculateTotalAmount();

        console.log(this.OrderItems);
      }
    },

    RemoveOrderItem(id) {
      this.OrderItems = this.OrderItems.filter((item) => item.id !== id);
      this.calculateTotalAmount();
    },

    IncreaseQuantity(id) {
      const itemIndex = this.OrderItems.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        this.OrderItems[itemIndex].quantity =
          parseInt(this.OrderItems[itemIndex].quantity) + 1;
        this.OrderItems[itemIndex].totalPrice =
          this.OrderItems[itemIndex].quantity *
          this.OrderItems[itemIndex].medicine_price;
        this.calculateTotalAmount();
      }

      console.log(this.OrderItems[itemIndex]);
    },
    DecreaseQuantity(id) {
      const itemIndex = this.OrderItems.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        if (parseInt(this.OrderItems[itemIndex].quantity) > 1) {
          this.OrderItems[itemIndex].quantity =
            parseInt(this.OrderItems[itemIndex].quantity) - 1;

          this.OrderItems[itemIndex].totalPrice =
            this.OrderItems[itemIndex].quantity *
            this.OrderItems[itemIndex].medicine_price;

          this.calculateTotalAmount();
        }
      }

      console.log(this.OrderItems[itemIndex]);
    },
    calculateTotalAmount() {
      this.totalAmount = this.OrderItems.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
    },

    SendBillInfo() {
      toast.success("Bill Information has been sent!!");
      this.$router.push({ name: "newRequests" });
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  padding: 10px 0;

  .col {
    width: 50%;
  }
}

.container {
  width: 100%;
  height: 80vh;
  padding: 20px;
  overflow-y: scroll;

  .firstRow {
    .col {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px;

      .search-main-contianer {
        width: 100%;
        position: relative;

        .searched-content-container {
          .SearchOverlay {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(20, 38, 56, 0.164);
          }
        }

        .search-container {
          border: 1px solid;
          width: 100%;
          border-radius: 50px;
          overflow: hidden;
          position: relative;
          z-index: 9999;

          input {
            width: 100%;
            padding: 10px;
            border: none;
            outline: none;
          }

          .searchIco {
            position: absolute;
            top: 2px;
            right: 25px;
            transform: translate(50%, 50%);
          }
        }

        .searched-content {
          width: 100%;
          max-height: 350px;
          background-color: white;
          box-shadow: 0 0 15px rgb(141, 141, 141);
          position: absolute;
          top: 150%;
          z-index: 9999;
          overflow-y: scroll;

          .Founditem {
            padding: 15px;
            border-bottom: 1px solid rgb(201, 201, 201);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .right {
              button {
                padding: 5px;
                background-color: rgb(28, 132, 201);
                color: white;
                border: none;
                cursor: pointer;
                &:hover {
                  background-color: rgb(25, 91, 134);
                }
              }
            }
          }
        }

        .SearchOverlay {
        }

        .notFound {
          padding: 10px;
        }
      }

      .selectedItems-container {
        align-self: flex-start;
        width: 100%;
        .selectedItem {
          display: flex;
          justify-content: space-between;
          border: 1px solid;
          padding: 15px;
          margin: 5px 0;

          .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .quantity-container {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;

              button {
                width: 25px;
                height: 25px;
                border-radius: 50px;
                border: none;
                color: white;
                background-color: var(--green);
                cursor: pointer;
                transition: 200ms ease;
                font-size: 17px;

                &:hover {
                  background-color: var(--green-dark);
                }
              }
            }
          }

          .right {
            display: flex;
            align-items: center;
            gap: 20px;

            button {
              border: none;
              background-color: transparent;
              color: rgb(243, 57, 82);
              transition: 200ms ease;
              cursor: pointer;
              font-size: 20px;

              &:hover {
                color: rgb(168, 40, 57);
              }
            }
          }
        }

        .lastContainer {
          padding: 10px 0;
          .total-container {
            display: flex;
            justify-content: space-between;

            h2 {
              font-size: 28px;
            }
          }
        }

        .button-container {
          button {
            padding: 10px;
            width: 100%;
            background-color: var(--green);
            color: white;
            border: none;
            padding: 15px;
            font-weight: bold;
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: var(--green-dark);
            }
          }
        }
      }

      // .row {
      //   .col {
      //     h3 {
      //       color: var(--green);
      //     }
      //   }
      // }
    }

    .img-container {
      width: 30%;
      height: 100%;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
      }
      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: 15px;
        border: none;
        background-color: var(--green);
        color: white;
        cursor: pointer;
        border-radius: 10px;
        opacity: 0;
        transition: 200ms ease;
      }

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.336);
        opacity: 0;
        transition: 200ms ease;
      }

      &:hover {
        button {
          opacity: 1;

          &:hover {
            background-color: var(--green-dark);
          }
        }

        .overlay {
          opacity: 1;
        }
      }
    }
  }

  .secondRow {
    .col {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .line {
        width: 75%;
        height: 1px;
        background-color: var(--green);
      }
    }
  }
  .thirdRow {
    .col {
      .search-container {
        border: 2px solid var(--gray);
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        input {
          width: 100%;
          padding: 10px;
          border: none;
          outline: none;
        }

        button {
          position: absolute;
          top: 10px;
          right: 10px;
          border: none;
          background-color: transparent;
          font-size: 14px;
          color: var(--gray);
        }
      }
    }
  }

  .fourthRow {
    .table-container {
      width: 100%;
      table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        thead {
          background-color: var(--green);

          th {
            padding: 20px;
            color: white;
          }
        }

        tbody {
          tr {
            td {
              padding: 5px;

              button {
                padding: 10px;
                background-color: transparent;

                color: rgb(240, 79, 79);
                border-radius: 5px;
                border: 1px solid rgb(240, 79, 79);
                cursor: pointer;
                transition: 200ms ease;

                &:hover {
                  background-color: rgb(240, 79, 79);
                  color: white;
                }
              }

              .quantity-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                .box {
                  width: 25px;
                  height: 25px;
                  border-radius: 50px;
                  background-color: var(--green);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  border: none;
                  transition: 200ms ease;

                  &:hover {
                    background-color: var(--green-dark);
                  }
                }
              }
            }

            &:nth-child(even) {
              background-color: var(--gray);
            }
          }
        }
      }
    }
  }

  .fifthRow {
    gap: 30px;
    .col {
      .card {
        width: 100%;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.329);
        padding: 20px;

        .card-header {
          color: var(--green);
        }

        .card-body {
          .input-container {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 27px;

            input {
              width: 100%;
              padding: 5px 0;
              border: none;
              border-bottom: 2px solid var(--gray);
              outline: none;
              transition: all 200ms ease;

              &:focus,
              &:valid {
                color: var(--green);
                border-bottom: 2px solid var(--green);

                + label {
                  transform: translateY(-1.05em);
                  color: var(--green);
                }
              }

              &:focus {
                &:before {
                  content: "sasd";
                }
              }
            }

            label {
              position: absolute;
              top: 0;
              left: 0;
              transition: all 200ms ease;
              cursor: text;
              color: gray;
            }
          }
        }

        .card-footer {
          border-top: 1px solid var(--green);

          .secondRow {
            button {
              width: 100%;
              padding: 15px;
              border-radius: 50px;
              background-color: var(--green-dark);
              color: white;
              transition: 200ms ease;
              border: none;
              cursor: pointer;

              &:hover {
                background-color: var(--green-darker);
              }
            }
          }
        }
      }
    }
  }
}
</style>
