import { createStore } from "vuex";

export default createStore({
  state: {
    medicines: [],
  },
  getters: {
    
  },
  mutations: {
    ADD_MEDICINE(state, payload) {
      state.medicines = payload;
    },
  },
  actions: {
    addMedicine({ commit }, payload) {
      commit("ADD_MEDICINE", payload);
    },
  },
  modules: {},
});
