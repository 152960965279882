<template>
  <div class="request-container">
    <div class="header">
      <div class="left">
        <ul>
          <router-link
            :to="{ name: 'newRequests' }"
            :class="{
              activeTab: $route.path.includes('/requests/new'),
            }"
          >
            <li>Registered Customers</li>
          </router-link>
        </ul>
      </div>
      <div class="right">
        <div class="search-container">
          <input
            type="text"
            spellcheck="false"
            placeholder="Search for something"
          />
        </div>
      </div>
    </div>

    <div class="body">
      <table>
        <thead>
          <th>No</th>
          <th>Full Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Date</th>

          <th>CTA</th>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>User 1</td>
            <td>0781262172</td>
            <td>Kicukiro</td>
            <td>YES</td>
            <td>
              <div class="cta-contianer">
                <button>Edit</button>
                <button class="delete">Delete</button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>User 2</td>
            <td>0781262172</td>
            <td>Kicukiro</td>
            <td>YES</td>
            <td>
              <div class="cta-contianer">
                <button>Edit</button>
                <button class="delete">Delete</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.request-container {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .header {
    padding: 25px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--green);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 15px;

        .activeTab {
          text-decoration: underline;
        }

        a {
          text-decoration: none;
          color: white;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right {
      .search-container {
        position: relative;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;

        input {
          border: none;
          padding: 10px 10px;
          outline: none;
        }

        button {
          position: absolute;
          top: 20px;
          right: 30px;
        }
      }
    }
  }

  .body {
    width: 100%;
    height: 70%;
    overflow-y: scroll;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      thead {
        background-color: var(--green);

        th {
          padding: 20px;
          color: white;
        }
      }

      tbody {
        tr {
          td {
            padding: 5px;

            .cta-contianer {
              display: flex;
              gap: 10px;
              justify-content: center;
            }

            button {
              padding: 10px;
              background-color: var(--green);
              color: white;
              border-radius: 5px;
              border: none;
              cursor: pointer;
              transition: 200ms ease;

              &:hover {
                background-color: var(--green-dark);
              }
            }
            .delete {
              background-color: transparent;
              border: 1px solid rgb(240, 79, 79);
              color: rgb(240, 79, 79);

              &:hover {
                background-color: rgb(240, 79, 79);
                color: white;
              }
            }
          }

          &:nth-child(even) {
            background-color: var(--gray);
          }
        }
      }
    }
  }
}
</style>
