<template>
  <div class="report-container">
    <TotalRequest />
    <completedRequests />
    <PendingRequests />
    <CanceledRequests />
  </div>
</template>

<script>
import TotalRequest from "./reports/components/TotalRequests.vue";
import completedRequests from "./reports/components/CompletedRequests.vue";
import PendingRequests from "./reports/components/PendingRequests.vue";
import CanceledRequests from "./reports/components/CanceledRequests.vue";

export default {
  components: {
    TotalRequest,
    completedRequests,
    PendingRequests,
    CanceledRequests,
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 80%;
  width: 100%;
  overflow-y: scroll;
}
</style>
