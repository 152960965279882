<template>
  <div class="dash-container">
    <div class="dash-sidebar" :class="{ ShowSidebar: !clickedMenu }">
      <div class="sidebar-header">
        <div class="logo">
          <img :src="logo" alt="" />
        </div>
      </div>

      <div class="sidebar-body">
        <div class="links-container">
          <ul>
            <router-link
              :to="{ name: 'dashboard' }"
              exact
              :class="{
                activeNavLink: $route.path.includes('/admin/dashboard'),
              }"
            >
              <li>Dashboard</li>
            </router-link>
            <router-link
              :to="$route.path.includes('requests') ? $route.path : '/admin/requests/new'"
              exact
              :class="{
                activeNavLink: $route.path.includes('/admin/requests'),
              }"
            >
              <li>Requests</li>
            </router-link>
            <router-link
              :to="{ name: 'customers' }"
              exact
              :class="{
                activeNavLink: $route.path.includes('/admin/customers'),
              }"
            >
              <li>Customers</li>
            </router-link>
            <router-link
              :to="$route.path.includes('inventory') ? $route.path : '/admin/inventory/view'"
              exact
              :class="{
                activeNavLink: $route.path.includes('/admin/inventory'),
              }"
            >
              <li>Inventory</li>
            </router-link>
            <router-link
              :to="{ name: 'reports' }"
              exact
              :class="{ activeNavLink: $route.path.includes('/admin/reports') }"
            >
              <li>Reports</li>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <div class="dash-content">
      <div class="main-contianer-header">
        <div class="left">
          <div
            class="container"
            :class="{ change: !clickedMenu }"
            @click="clickedMenu = !clickedMenu"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </div>
        <div class="center">
          <h2 id="timeDisplay">{{ timeString }}</h2>
          <h4 id="dateDisplay">{{ formattedDate }}</h4>
        </div>
        <div class="right">
          <div class="right-toggle">
            <div>
              <input type="checkbox" class="checkbox" id="checkbox" />
              <label for="checkbox" class="checkbox-label">
                <i class="fas fa-moon"></i>
                <i class="fas fa-sun"></i>
                <span class="ball"></span>
              </label>
            </div>
          </div>
          <button @click="logout">Logout <i class="fa fa-sign-out"></i></button>
        </div>
      </div>
      <router-view v-slot="{ Component }">
        <transition name="slide" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import logo from "../assets/img/logo.png";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  data() {
    return {
      clickedMenu: false,
      logo: logo,
      timeString: "",
      formattedDate: "",
    };
  },
  mounted() {
    setInterval(this.updateTime, 1000);
    this.updateTime();

    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  },
  methods: {
    updateTime() {
      var currentTime = new Date();
      var hours = currentTime.getHours();
      var minutes = currentTime.getMinutes();
      var seconds = currentTime.getSeconds();
      var ampm = hours >= 12 ? "PM" : "AM";

      // Convert hours from 24-hour format to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // 0 should be displayed as 12

      // Add leading zero to minutes and seconds if they are less than 10
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // Create the time string in the format of "hh:mm:ss AM/PM"
      this.timeString = hours + ":" + minutes + ":" + seconds + " " + ampm;

      // Format the date as "14th - Nov - 2023"
      this.formattedDate = this.formatDate(currentTime);
    },
    formatDate(date) {
      var day = date.getDate();
      var dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var year = date.getFullYear();

      // Add the appropriate suffix to the day
      var daySuffix;
      if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
      } else if (day === 2 || day === 22) {
        daySuffix = "nd";
      } else if (day === 3 || day === 23) {
        daySuffix = "rd";
      } else {
        daySuffix = "th";
      }

      return dayOfWeek + ", " + day + daySuffix + month + " - " + year;
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      toast.success("You are logged out!");

      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.dash-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;

  .dash-sidebar {
    width: 0%;
    height: 100vh;
    background-color: var(--gray-light);
    transition: all 200ms ease;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;

    + .dash-content {
      width: 100%;
    }

    .sidebar-header {
      width: 100%;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50%;
        }
      }
    }

    .sidebar-body {
      width: 100%;

      .links-container {
        width: 100%;
        padding-left: 20px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 19px;
          list-style-type: none;

          a {
            max-width: 100%;

            padding: 15px 30px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            text-decoration: none;
            color: var(--green-dark);
            transition: 500ms ease;
            font-weight: bold;

            // li {
            // }
          }

          .activeNavLink {
            background: white;
            padding: 15px 30px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
          }
        }
      }
    }
  }

  .ShowSidebar {
    width: 20%;
    + .dash-content {
      width: 80%;
    }
  }

  .dash-content {
    width: 80%;
    min-height: 100vh;
    // overflow-y: scroll;
    transition: all 200ms ease;

    .main-contianer-header {
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid var(--gray-light);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        .container {
          cursor: pointer;

          .bar1,
          .bar2,
          .bar3 {
            width: 30px;
            height: 3px;
            background-color: var(--green);
            margin: 6px 0;
            transition: 0.4s;
          }
        }

        .change {
          .bar1 {
            -webkit-transform: rotate(-45deg) translate(-9px, 8px);
            transform: rotate(-45deg) translate(-4px, 4px);
          }

          .bar2 {
            opacity: 0;
          }

          .bar3 {
            -webkit-transform: rotate(45deg) translate(-8px, -8px);
            transform: rotate(45deg) translate(-8px, -8px);
          }
        }
      }

      .center {
        text-align: center;

        #dateDisplay {
          color: var(--green);
        }
        #timeDisplay {
          font-size: 35px;

          -webkit-text-stroke: 1px var(--green);
          color: transparent;
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 20px;
        button {
          padding: 10px;
          border-radius: 50px;
          border: 1px solid rgb(240, 79, 79);
          color: rgb(240, 79, 79);
          background-color: transparent;
          transition: 200ms ease;
          cursor: pointer;

          &:hover {
            background-color: rgb(240, 79, 79);
            color: white;
          }
        }

        .right-toggle {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }

        .checkbox {
          opacity: 0;
          position: absolute;
        }

        .checkbox-label {
          background-color: var(--green-dark);
          width: 50px;
          height: 26px;
          border-radius: 50px;
          position: relative;
          padding: 5px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .fa-moon {
          color: white;
        }

        .fa-sun {
          color: white;
        }

        .checkbox-label .ball {
          background-color: #fff;
          width: 22px;
          height: 22px;
          position: absolute;
          left: 2px;
          top: 2px;
          border-radius: 50%;
          transition: transform 0.2s linear;
        }

        .checkbox:checked + .checkbox-label .ball {
          transform: translateX(24px);
        }
      }
    }
  }
}

/* Styles of Transition Start */
.slide-enter-active,
.slide-leave-active {
  transition: all 600ms ease;
}

.slide-enter-to {
  opacity: 1;
  transition-delay: 300ms;
}

.slide-enter-from {
  opacity: 0;
}

.slide-leave-to {
  transform: scale(0.8);
  opacity: 0;
}

.slide-leave-from {
  transform: scale(1);
  opacity: 1;
}

/* Styles of Transition End */
</style>
