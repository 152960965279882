<template>
  <div class="add-container">
    <div class="left">
      <div class="card">
        <form @submit.prevent>
          <div class="row">
            <div class="col">
              <div class="input-container">
                <input
                  type="text"
                  id="medicineName"
                  v-model="medicine_name"
                  autocomplete="off"
                  required
                />
                <label for="medicineName">Medicine Name</label>
              </div>
            </div>
            <div class="col">
              <div class="input-container">
                <input
                  type="text"
                  id="username"
                  v-model="medicine_price"
                  autocomplete="off"
                  required
                />
                <label for="username">Medicine Cost (frw)</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="medicine-form">
              <div class="header">
                <p>Medicine Form</p>
              </div>

              <div class="body">
                <div class="input-container">
                  <input
                    type="checkbox"
                    v-model="checkedItems"
                    value="Tablets"
                    id="tablets"
                  />
                  <label for="tablets">Tablets</label>
                </div>
                <div class="input-container">
                  <input
                    type="checkbox"
                    v-model="checkedItems"
                    value="Liquid"
                    id="liquid"
                  />
                  <label for="liquid">Liquid</label>
                </div>
                <div class="input-container">
                  <input
                    type="checkbox"
                    v-model="checkedItems"
                    value="Inhalers"
                    id="inhalers"
                  />
                  <label for="inhalers">Inhalers</label>
                </div>
                <div class="input-container">
                  <input
                    type="checkbox"
                    v-model="checkedItems"
                    value="Syringe"
                    id="syringe"
                  />
                  <label for="syringe">Syringe</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-container">
                <input
                  type="text"
                  id="medicineTotal"
                  v-model="total_medicine"
                  autocomplete="off"
                  required
                />
                <label for="medicineTotal">Total Medicines</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="description-container">
              <p>Short Description</p>
              <textarea
                name=""
                id=""
                spellcheck="false"
                v-model="description"
                cols="30"
                rows="5"
                placeholder="Say something about the medicine"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="medicine-form">
              <div class="body">
                <div class="input-container">
                  <input
                    type="checkbox"
                    v-model="visibility"
                    name=""
                    id="visible"
                  />
                  <label for="visible">Make it visible</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="button-container">
              <button @click="addMedicine">Add Medicine</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="right">
      <div class="right-container">
        <div
          class="box"
          v-if="imageUrl"
          :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
        ></div>
        <div class="box" v-else></div>
        <div class="input-container">
          <input
            type="file"
            ref="fileInput"
            @change="previewImage"
            id="uploadImage"
          />
          <button v-if="!imageUrl" @click="openFileUpload">
            Choose Medicine Image
          </button>
          <button v-else @click="openFileUpload">Change Medicine Image</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  data() {
    return {
      imageUrl: "",
      medicine_name: "",
      medicine_price: "",
      medicine_form: "",
      total_medicine: "",
      description: "",
      visibility: null,
      checkedItems: [],
      image: null,
    };
  },
  methods: {
    openFileUpload() {
      this.$refs.fileInput.click();
    },

    previewImage(event) {
      const file = event.target.files[0];
      this.image = this.$refs.fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async addMedicine() {
      this.medicine_form = this.checkedItems.join(",");

      await axios
        .post(
          "https://scoreapi.gotmine.rw/api/medicine",
          {
            medicine_name: this.medicine_name,
            medicine_price: this.medicine_price,
            medicine_form: this.medicine_form,
            total_medicine: this.total_medicine,
            description: this.description,
            visibility: this.visibility ? "show" : "hidden",
            image: this.image,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          Toast.success("Medicine was added successfully!");
          if(response.data.success) {
            this.medicine_name = ""
            this.medicine_form = ""
            this.medicine_price = ""
            this.total_medicine = ""
            this.description = ""
            this.visibility = null
            this.image = null 
            this.checkedItems = []
            this.imageUrl = ""
            this.$router.push({name: 'viewMedicine'})
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  width: 100%;
  // height: 100vh;
  display: flex;
  padding: 20px;
  gap: 20px;

  .left {
    width: 60%;

    .card {
      width: 100%;
      min-height: 380px;
      box-shadow: 0 0 15px rgb(207, 207, 207);
      padding: 20px;

      .row {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        padding: 10px 0;

        .col {
          width: 50%;
          .input-container {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 27px;

            input {
              width: 100%;
              padding: 5px 0;
              border: none;
              border-bottom: 2px solid var(--gray);
              outline: none;
              transition: all 200ms ease;

              &:focus,
              &:valid {
                color: var(--green);
                border-bottom: 2px solid var(--green);

                + label {
                  transform: translateY(-1.05em);
                  color: var(--green);
                }
              }

              &:focus {
                &:before {
                  content: "sasd";
                }
              }
            }

            label {
              position: absolute;
              top: 0;
              left: 0;
              transition: all 200ms ease;
              cursor: text;
              color: gray;
            }
          }
        }

        .medicine-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .body {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .input-container {
              display: flex;
              gap: 10px;
            }
          }
        }

        .description-container {
          width: 100%;
          textarea {
            border: 2px solid var(--gray);
            outline: none;
            transition: 200ms ease;
            width: 100%;
            padding: 5px;
            color: gray;

            &::placeholder {
              color: rgb(175, 175, 175);
            }
            &:focus {
              border: 2px solid var(--green);
              color: var(--green);
            }
          }
        }

        .button-container {
          width: 100%;
          button {
            padding: 15px;
            width: 100%;
            background-color: var(--green);
            border: none;
            color: white;
            font-weight: bold;
            transition: 200ms ease;
            cursor: pointer;

            &:hover {
              background-color: var(--green-dark);
            }
          }
        }
      }
    }
  }

  .right {
    width: 40%;
    position: relative;

    .right-container {
      position: sticky;
      top: 20px;
      .box {
        width: 100%;
        height: 200px;
        background-color: var(--gray);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .input-container {
        input {
          display: none;
        }

        button {
          width: 100%;
          padding: 10px;
          border-radius: 50px;
          background-color: var(--green);
          padding: 15px;
          color: white;
          font-weight: bold;
          margin-top: 10px;
          border: none;
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            background-color: var(--green-dark);
          }
        }
      }
    }
  }
}
</style>
