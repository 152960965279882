<template>
  <div class="base-container">
    <div class="left">
      <div class="overlay"></div>
      <div class="left-content">
        <h2>Score Pharmacy Admin Tools</h2>

        <div class="details">
          <div class="line"></div>
          <p>Securely access on {{ currentDate }}.</p>
        </div>
        <p class="foot">
          Score Pharmacy | Powered <a href="#">Innovative VAS</a>
        </p>
      </div>
    </div>
    <div class="right">
      <div class="right-header">
        <img :src="logo" alt="" />
        <div class="words-container">
          <h2>Score</h2>
          <h2>Pharmacy</h2>
          <h2>Admin Portal</h2>
        </div>
      </div>

      <div class="right-body">
        <form @submit.prevent spellcheck="false">
          <div class="form-header">
            <h2>Login</h2>
          </div>
          <div class="form-body">
            <div class="input-container">
              <input
                type="text"
                id="username"
                v-model="username"
                autocomplete="off"
                required
              />
              <label for="username">username</label>
            </div>
            <div class="input-container">
              <input
                type="password"
                id="password"
                v-model="password"
                autocomplete="off"
                required
              />
              <label for="password">Password</label>
            </div>
            <div class="check-container">
              <input type="checkbox" id="rememberMe" v-model="rememberMe" />
              <label for="rememberMe">Remember Me</label>
            </div>

            <div class="btn-container">
              <button @click="login">Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import bgImg from "../assets/img/hospitalBg.jpg";
import logo from "../assets/img/logo.png";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  data() {
    return {
      bgImg,
      logo,
      currentDate: "",
      username: "",
      password: "",
      rememberMe: "",
    };
  },
  mounted() {
    if (
      localStorage.getItem("LoggedIn") ||
      sessionStorage.getItem("LoggedIn")
    ) {
      this.$router.push("/admin");
    }

    const fulldate = new Date();
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const getDaySuffix = (day) => {
      if (day === 1 || day === 12 || day === 3) {
        return "th";
      } else {
        const lastDigit = day % 10;

        if (lastDigit === 1) {
          return "st";
        } else if (lastDigit === 2) {
          return "nd";
        } else if (lastDigit === 3) {
          return "rd";
        } else {
          return "th";
        }
      }
    };

    this.currentDate =
      fulldate.getDate() +
      getDaySuffix(fulldate.getDate()) +
      " " +
      monthAbbreviations[fulldate.getMonth()];
  },
  methods: {
    login() {
      if (this.username.length > 0 && this.password.length > 0) {
        axios
          .post("https://scoreapi.gotmine.rw/api/admin/signin", {
            username: this.username,
            password: this.password,
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.success == true) {
              const data = response.data.data;
              console.log(data);

              // if user is logged and clicked remember Me, store data is localstorage

              toast.success(response.data.message);

              if (this.rememberMe) {
                localStorage.setItem("userId", data.userId);
                localStorage.setItem("username", data.username);
                localStorage.setItem("token", data.token);
                localStorage.setItem("LoggedIn", true);
                this.$router.push("/admin");
              } else {
                // if user is logged but didn't click remember me, store data is sessionStorage

                sessionStorage.setItem("userId", data.userId);
                sessionStorage.setItem("username", data.username);
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem("LoggedIn", true);
                this.$router.push("/admin");
              }
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        toast.error("Please first fill the inputs!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .left {
    width: 65%;
    height: 100vh;
    background-image: url(../assets/img/hospitalBg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    overflow: hidden;
    box-shadow: 0 0 15px rgb(56, 58, 56);

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;

      background: linear-gradient(
        90deg,
        rgba(126, 126, 126, 0.264) 0%,
        rgb(0, 0, 0) 100%
      );
      z-index: -1;
    }

    .left-content {
      width: 100%;
      height: 100vh;
      padding: 0 10%;
      color: white;
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;

      h2 {
        font-size: 70px;
      }

      .details {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }

      p {
        font-size: 20px;
      }

      .line {
        width: 50px;
        height: 1px;
        background-color: white;
      }
      .foot {
        position: fixed;
        bottom: 50px;
        font-size: 17px;

        a {
          color: rgb(121, 236, 121);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .right {
    width: 35%;
    height: 100vh;
    padding: 2% 5%;

    .right-header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        width: 150px;
      }
      .words-container {
        font-size: 20px;
        color: var(--green);
      }
    }

    .right-body {
      margin-top: 20px;
      form {
        .form-header {
          // text-align: center;
          color: var(--green);
        }
        .form-body {
          display: flex;
          flex-direction: column;
          // gap: 50px;
          // margin-top: 25px;
          .input-container {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 27px;

            input {
              width: 100%;
              padding: 5px 0;
              border: none;
              border-bottom: 2px solid var(--gray);
              outline: none;
              transition: all 200ms ease;

              &:focus,
              &:valid {
                color: var(--green);
                border-bottom: 2px solid var(--green);

                + label {
                  transform: translateY(-1.05em);
                  color: var(--green);
                }
              }

              &:focus {
                &:before {
                  content: "sasd";
                }
              }
            }

            label {
              position: absolute;
              top: 0;
              left: 0;
              transition: all 200ms ease;
              cursor: text;
              color: gray;
            }
          }

          .check-container {
            display: flex;
            align-items: center;
            margin: 25px 0;
            gap: 10px;

            input[type="checkbox"] {
              width: 17px;
              height: 20px;
              border-radius: 2100px;
              accent-color: var(--green);
              outline: none;
            }

            label {
              color: gray;
            }
          }
          .btn-container {
            button {
              width: 100%;
              padding: 15px;
              border: none;
              background-color: var(--green);
              color: white;
              cursor: pointer;
              transition: 200ms ease;

              &:hover {
                background-color: var(--green-dark);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 838px) {
  .base-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // overflow: hidden;

    .left {
      width: 100%;
      height: 40vh;
      background-image: url(../assets/img/hospitalBg.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      z-index: 0;
      overflow: hidden;
      box-shadow: 0 0 15px rgb(56, 58, 56);

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;

        background: linear-gradient(
          90deg,
          rgba(126, 126, 126, 0.264) 0%,
          rgb(0, 0, 0) 100%
        );
        z-index: -1;
      }

      .left-content {
        width: 100%;
        height: 30vh;
        // padding: 0 10%;
        color: white;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        h2 {
          font-size: 40px;
          color: white;
        }

        .details {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          gap: 20px;
        }

        p {
          font-size: 20px;
        }

        .line {
          width: 25px;
          height: 1px;
          background-color: white;
        }
        .foot {
          display: none;
          position: fixed;
          bottom: 50px;
          font-size: 17px;
          color: gray;

          a {
            color: rgb(121, 236, 121);
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .right {
      width: 100%;
      min-height: 60vh;
      padding: 2% 10%;
      overflow-y: scroll;

      .right-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: 110px;
        }
        .words-container {
          font-size: 10px;
          color: var(--green);
        }
      }

      .right-body {
        margin-top: 20px;
        form {
          .form-header {
            // text-align: center;
            color: var(--green);
          }
          .form-body {
            display: flex;
            flex-direction: column;
            // gap: 50px;
            // margin-top: 25px;
            .input-container {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 27px;

              input {
                width: 100%;
                padding: 5px 0;
                border: none;
                border-bottom: 2px solid var(--gray);
                outline: none;
                transition: all 200ms ease;

                &:focus,
                &:valid {
                  color: var(--green);
                  border-bottom: 2px solid var(--green);

                  + label {
                    transform: translateY(-1.05em);
                    color: var(--green);
                  }
                }

                &:focus {
                  &:before {
                    content: "sasd";
                  }
                }
              }

              label {
                position: absolute;
                top: 0;
                left: 0;
                transition: all 200ms ease;
                cursor: text;
                color: gray;
              }
            }

            .check-container {
              display: flex;
              align-items: center;
              margin: 25px 0;
              gap: 10px;

              input[type="checkbox"] {
                width: 17px;
                height: 20px;
                border-radius: 2100px;
                accent-color: var(--green);
                outline: none;
              }

              label {
                color: gray;
              }
            }
            .btn-container {
              button {
                width: 100%;
                padding: 15px;
                border: none;
                background-color: var(--green);
                color: white;
                cursor: pointer;
                transition: 200ms ease;

                &:hover {
                  background-color: var(--green-dark);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
