<template>
  <div class="inventory-container">
    <div class="header">
      <div class="left">
        <ul>
          <router-link
            :to="{ name: 'viewMedicine' }"
            :class="{
              activeTab: $route.path.includes('/inventory/view'),
            }"
          >
            <li>view Medicines</li>
          </router-link>
          <router-link :to="{name: 'addMedicine'}" :class="{
              activeTab: $route.path.includes('/inventory/add'),
            }">
            <li>Add Medicine</li>
          </router-link>
        </ul>
      </div>
      <div class="right">
        <div class="search-container">
          <input
            type="text"
            spellcheck="false"
            v-model="filter"
            placeholder="Search for something"
          />
        </div>
      </div>
    </div>

    <div class="body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.inventory-container {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .header {
    padding: 25px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--green);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 15px;

        .activeTab {
          text-decoration: underline;
        }

        a {
          text-decoration: none;
          color: white;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right {
      .search-container {
        position: relative;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;

        input {
          border: none;
          padding: 10px 10px;
          outline: none;
        }

        button {
          position: absolute;
          top: 20px;
          right: 30px;
        }
      }
    }
  }

  .body {
    width: 100%;
    height: 70%;
    overflow-y: scroll;
  }
}
</style>
