<template>
  <div class="table-main-container">
    <table>
      <thead>
        <th>No</th>
        <th>Full Name</th>
        <th>Phone</th>
        <th>Address</th>
        <th>Date</th>
        <th>Uploaded Image</th>
        <th>CTA</th>
      </thead>
      <tbody>
        <tr v-for="(order, index) in orders" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ order.fullname }}</td>
          <td>{{ order.phone }}</td>
          <td>{{ order.address }}</td>
          <td>{{ order.date.slice(0, 10) }}</td>
          <td>{{ order.uploads == 1 ? "Yes" : "No" }}</td>
          <td>
            <button
              v-if="order.uploads == 1"
              @click="
                this.$router.push({
                  name: 'processRequest',
                  params: { id: order.id },
                })
              "
            >
              Process Request
            </button>
            <button v-else @click="openModal(order.medicines, order.id)">
              View Request
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-wrapper">
    <teleport to="body">
      <transition name="fade">
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal" @click.stop>
            <div class="modal-header">
              <h2>Request Information</h2>
              <div class="circle" @click="closeModal">
                <i class="fa fa-close"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="table-container">
                <table border="1">
                  <thead>
                    <th>No</th>
                    <th>Medicine Name</th>
                    <th>Form</th>
                    <th>Quantity</th>
                    <th>Amount Paid</th>
                  </thead>

                  <tbody v-if="medicines">
                    <tr v-for="(medicine, index) in medicines" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ medicine.medicine_name }}</td>
                      <td>tablets</td>
                      <td>14</td>
                      <td>1200 frw</td>
                    </tr>
                    <tr>
                      <td colspan="4">Total Amount</td>
                      <td><strong>2400 Frw</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="watermark">
                <h1>PAID</h1>
              </div>
            </div>
            <div class="button-container">
              <button @click="completeRequest">Complete Request</button>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  data() {
    return {
      orders: "",
      showModal: false,
      medicines: "",
      selectedOrderId: "",
      shouldRender: true,
    };
  },
  async mounted() {
    await axios
      .get("https://scoreapi.gotmine.rw/api/orders/fetch")
      .then((response) => {
        const allOrders = response.data.data;

        // Check if allOrders is defined and not empty
        if (allOrders && allOrders.length > 0) {
          const newOrders = allOrders.filter(
            (order) => order.order_status === "new"
          );
          this.orders = newOrders;
        } else {
          // Handle the case where allOrders is empty or undefined
          console.log("No orders found");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    openModal(medicines, orderId) {
      this.showModal = true;
      this.selectedOrderId = orderId;

      this.medicines = medicines;
    },
    closeModal() {
      this.showModal = false;
    },
    completeRequest() {
      axios
        .post("https://scoreapi.gotmine.rw/api/orders/status", {
          order_status: "completed",
          id: this.selectedOrderId,
        })
        .then((response) => {
          this.closeModal();

          this.orders = this.orders.filter(
            (order) => order.id != this.selectedOrderId
          );

          Toast.success(response.data.message);
        })

        .catch((error) => {
          Toast.error(error);
          console.log(error);
        });
    },
    remountComponent() {
      // Toggle the flag to trigger remounting of the component
      this.shouldRender = false;
      this.$nextTick(() => {
        this.shouldRender = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-main-container {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    thead {
      background-color: var(--green);

      th {
        padding: 20px;
        color: white;
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;

          button {
            padding: 10px;
            background-color: var(--green);
            color: white;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: var(--green-dark);
            }
          }
        }

        &:nth-child(even) {
          background-color: var(--gray);
        }
      }
    }
  }
}

.modal-wrapper {
  position: relative;
  // overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(15px);
}

.modal {
  background: white;
  // padding: 20px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  min-width: 50%;
  height: 70vh;
  overflow: hidden;

  .modal-header {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-bottom: 3px solid var(--green);
    padding: 20px;

    .circle {
      background-color: var(--green-500);
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        background-color: var(--green-600);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 20px;
    position: relative;
    overflow-y: scroll;
    height: 300px;

    .table-container {
      z-index: 999999;
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        border-radius: 40px;

        thead {
          border-top-left-radius: 40px;
          th {
            padding: 10px;
          }
        }

        tbody {
          tr {
            td {
              padding: 5px;
            }
          }
        }
      }
    }

    .watermark {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%);

      h1 {
        font-size: 185px;
        color: rgba(10, 10, 26, 0.075);
        transform: rotate(-30deg);
      }
    }

    // .modal-body-container {
    //   display: flex;
    //   gap: 30px;
    //   .modal-left {
    //     width: 35%;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 10px;

    //     .preview {
    //       width: 100%;
    //       height: 250px;
    //       background-color: rgb(204, 204, 204);
    //       border-radius: 5px;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       background-position: center;
    //       background-size: cover;
    //       background-repeat: no-repeat;

    //       .uplodIcon {
    //         font-size: 90px;
    //         color: rgba(128, 128, 128, 0.329);
    //       }
    //     }

    //     .input-container {
    //       width: 100%;

    //       input {
    //         display: none;
    //       }
    //     }
    //     .uploadBtn {
    //       width: 100%;
    //       background-color: var(--green-500);
    //       padding: 13px;
    //       border: none;
    //       color: white;
    //       font-weight: bold;
    //       cursor: pointer;
    //       transition: 200ms ease;

    //       &:hover {
    //         background-color: var(--green-600);
    //       }
    //     }
    //   }
    //   .modal-right {
    //     width: 65%;

    //     .name-phone-container {
    //       display: flex;
    //       align-items: center;
    //       gap: 20px;
    //     }

    //     .input-container {
    //       position: relative;
    //       display: flex;
    //       flex-direction: column;
    //       margin-top: 27px;

    //       input {
    //         width: 100%;
    //         padding: 5px 0;
    //         border: none;
    //         border-bottom: 2px solid var(--gray);
    //         outline: none;
    //         transition: all 200ms ease;

    //         &:focus,
    //         &:valid {
    //           color: var(--green-500);
    //           border-bottom: 2px solid var(--green-200);

    //           + label {
    //             transform: translateY(-1.05em);
    //             font-size: 12px;
    //             color: var(--green);
    //           }
    //         }

    //         &:focus {
    //           &:before {
    //             content: "sasd";
    //           }
    //         }
    //       }

    //       label {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         transition: all 200ms ease;
    //         cursor: text;
    //         color: gray;
    //       }

    //       select {
    //         width: 100%;
    //         padding: 5px 0;
    //         border: none;
    //         border-bottom: 2px solid var(--gray);
    //         outline: none;
    //         transition: all 200ms ease;
    //         background-color: transparent;

    //         &:valid {
    //           border-bottom: 2px solid var(--green-500);
    //           color: var(--green-500);
    //         }
    //       }
    //     }
    //   }
    // }
    // .submitBtn {
    //   width: 100%;
    //   background-color: var(--green-500);
    //   padding: 13px;
    //   border: none;
    //   color: white;
    //   font-weight: bold;
    //   cursor: pointer;
    //   transition: 200ms ease;

    //   &:hover {
    //     background-color: var(--green-600);
    //   }
    // }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;

    button {
      padding: 10px;
      background-color: var(--green);
      color: white;
      border: none;
      border-radius: 5px;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        background-color: var(--green-dark);
      }
    }
  }
}

.fade-enter-active {
  $modalOpen: True;
  @if $modalOpen == True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
