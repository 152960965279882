<template>
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="header1">
        <div class="" style="display: flex; flex-direction: column; gap: 10px">
          <p>Hi, here is your daily stats</p>
          <h1>Dashboard</h1>
        </div>
      </div>
      <div class="header2">
        <div class="box" ref="box1" data-tilt>
          <div class="left">
            <div class="circle">
              <i class="fas fa-bell"></i>
            </div>
          </div>
          <div class="right">
            <div class="right-container">
              <h1>24</h1>
              <p>New Requests</p>
            </div>
          </div>
        </div>

        <div class="box" ref="box2" data-tilt>
          <div class="left">
            <div class="circle">
              <i class="fa fa-clock"></i>
            </div>
          </div>
          <div class="right">
            <div class="right-container">
              <h1>52</h1>
              <p>Pending Requests</p>
            </div>
          </div>
        </div>

        <div class="box" ref="box3" data-tilt>
          <div class="left">
            <div class="circle">
              <i class="fa fa-check"></i>
            </div>
          </div>
          <div class="right">
            <div class="right-container">
              <h1>1825</h1>
              <p>Completed Requests</p>
            </div>
          </div>
        </div>

        <div class="box" ref="box4" data-tilt>
          <div class="left">
            <div class="circle">
              <i class="fa fa-file-circle-xmark"></i>
            </div>
          </div>
          <div class="right">
            <div class="right-container">
              <h1>1000</h1>
              <p>Canceled Requests</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-body">
      <div class="left">
        <canvas id="myChart"></canvas>
      </div>
      <div class="right-body">
        <div class="notification-section">
          <div class="notification-header">
            <h2>Notification</h2>
          </div>

          <div class="notification-body">
            <div class="notification">
              <div class="left"></div>
              <div class="right">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Dolore, hic.
                </p>
                <p class="time">5 hours ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VanillaTilt from "vanilla-tilt";
import { onMounted, ref } from "vue";
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  mounted() {
    axios
      .get("https://scoreapi.gotmine.rw/api/medicine")
      .then((response) => {
        if(response.data.success) {
          this.$store.dispatch('addMedicine', response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const ctx = document.getElementById("myChart");

    Chart.defaults.backgroundColor = "#228a38";
    // Chart.defaults.borderColor = "";
    Chart.defaults.color = "#228a38";

    new Chart(ctx, {
      type: "line",
      data: {
        labels: ["Last Month", "Last Week", "Yesterday", "Today"],
        datasets: [
          {
            label: "# Total Sales",
            data: [12, 5, 19, 13],
            borderWidth: 1,
            tension: 0.2,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  },
  setup() {
    const box1 = ref(null);
    const box2 = ref(null);
    const box3 = ref(null);
    const box4 = ref(null);

    onMounted(() => {
      VanillaTilt.init(box1.value, { max: 15, speed: 800 });
      VanillaTilt.init(box2.value, { max: 15, speed: 800 });
      VanillaTilt.init(box3.value, { max: 15, speed: 800 });
      VanillaTilt.init(box4.value, { max: 15, speed: 800 });
    });

    return {
      box1,
      box2,
      box3,
      box4,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  .dashboard-header {
    min-height: 200px;
    background-color: rgb(241, 241, 241);
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .header1 {
      display: flex;
      justify-content: space-between;
      align-items: centre;
      gap: 10px;
      color: var(--green-dark);
    }

    .header2 {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .box {
        width: 100%;
        height: 100px;
        border: 1px solid var(--green);
        border-radius: 10px;
        display: flex;

        &:hover {
          transform-style: preserve-3d;
          transform: perspective(1000px);

          .left,
          .right {
            transform: translateZ(20px);
          }
        }

        .left {
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;

          .circle {
            width: 60px;
            height: 60px;
            background-color: var(--green-dark);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 30px;
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;

          .right-container {
            color: var(--green-dark);
          }
        }

        &:first-child {
          background-color: var(--green-dark);

          .left {
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;

            .circle {
              width: 60px;
              height: 60px;
              background-color: white;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--green-dark);
              font-size: 30px;
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;

            .right-container {
              color: white;
            }
          }
        }
      }
    }
  }

  .dashboard-body {
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 30px;

    .left {
      width: 50%;
      height: 100%;

      #myChart {
        width: 100%;
        height: 300px;
      }
    }
    .right-body {
      width: 50%;
      border-left: 1px solid var(--gray-light);
      //   padding: 0 30px;

      .notification-section {
        width: 100%;

        .notification-header {
          width: 100%;
          padding: 0 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--gray-light);
          color: var(--green-dark);
        }

        .notification-body {
          .notification {
            // padding: 0 20px;
            padding: 20px 20px;
            border-bottom: 1px solid var(--gray-light);
            transition: 200ms ease;

            &:hover {
              background-color: var(--gray-light);
            }

            .right {
              .time {
                color: gray;
              }
            }
          }
        }
      }
    }
  }
}
</style>
