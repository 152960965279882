<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --gray: rgb(207, 207, 207);
  --gray-light: rgb(223, 223, 223);
  --green: #2db24a;
  --green-dark: #228a38;
  --green-darker: #196429;
}

body {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #2c3e50;
  font-size: 15px;
}




</style>
