<template>
  <div class="request-container">
    <div class="header">
      <div class="left">
        <ul>
          <router-link
            :to="{ name: 'newRequests' }"
            :class="{
              activeTab: $route.path.includes('/requests/new'),
            }"
          >
            <li>New Requests</li>
          </router-link>
          <router-link :to="{ name: 'pendingRequests' }" :class="{
              activeTab: $route.path.includes('/requests/pending'),
            }">
            <li>Pending Requests</li>
          </router-link>
          <router-link :to="{ name: 'completedRequests' }" :class="{
              activeTab: $route.path.includes('/requests/completed'),
            }">
            <li>Completed Requests</li>
          </router-link>
        </ul>
      </div>
      <div class="right">
        <div class="search-container">
          <input
            type="text"
            spellcheck="false"
            placeholder="Search for something"
          />
        </div>
      </div>
    </div>

    <div class="body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.request-container {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .header {
    padding: 25px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--green);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 15px;

        .activeTab {
          text-decoration: underline;
        }

        a {
          text-decoration: none;
          color: white;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right {
      .search-container {
        position: relative;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;

        input {
          border: none;
          padding: 10px 10px;
          outline: none;
        }

        button {
          position: absolute;
          top: 20px;
          right: 30px;
        }
      }
    }
  }

  .body {
    width: 100%;
    height: 70%;
    overflow-y: scroll;
  }
}
</style>
